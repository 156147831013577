import React from 'react';
import { Box, Flex, FormControl, InputRadio } from '@itandi/itandi-bb-ui';
import Styles from './InvoiceItemAddForm.module.scss';
import { DealConstructionEdit } from '~/models/DealConstruction';
import { DealSettlementEdit } from '~/models/DealSettlement';
import { NskResponse } from '~/models/Api';
import { useUsingApplications } from '~/hooks/useUsingApplications';
import { SettlementAddForm } from '../../settlements/adding/SettlementAddForm';
import { ConstructionAddForm } from './ConstructionAddForm';
import { useConstructionItems } from '~/hooks/useConstructionItems';

type Props = {
  billingType: 'owner' | 'rentee';
  handleCreate: (
    submitValue: DealConstructionEdit | DealSettlementEdit
  ) => Promise<NskResponse<void>>;
  mutateConstructions: () => Promise<void>;
};
export const InvoiceItemAddForm: React.FC<Props> = ({
  billingType,
  handleCreate,
  mutateConstructions
}: Props) => {
  const [selectedOption, setSelectedOption] = React.useState('construction');
  const { useSK } = useUsingApplications();
  const { data: constructionItems } = useConstructionItems().index();
  if (constructionItems === null) return null;
  return (
    <div className={Styles.EstimateAddForm}>
      {!useSK && (
        <Box margin="8px">
          <FormControl chip="必須" label="項目選択" variant="Vertical">
            <Flex gap="16px">
              <InputRadio
                checked={selectedOption === 'construction'}
                label="施工項目"
                onChange={(): void => setSelectedOption('construction')}
                value="construction"
              />
              <InputRadio
                checked={selectedOption === 'settlement'}
                label="精算項目"
                onChange={(): void => setSelectedOption('settlement')}
                value="settlement"
              />
            </Flex>
          </FormControl>
        </Box>
      )}
      {selectedOption === 'construction' ? (
        <ConstructionAddForm
          constructionItems={constructionItems}
          handleCreate={handleCreate}
          mutateConstructions={mutateConstructions}
        />
      ) : (
        <SettlementAddForm
          billingType={billingType}
          handleCreate={handleCreate}
          mutateConstructions={mutateConstructions}
        />
      )}
    </div>
  );
};
